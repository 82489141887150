<template>
  <el-card class="content">
    <div class="box">
      <h3 class="g">厂家信息</h3>
      <div class="box-item">
        <div class="box-lf">
          <span>厂家名称</span>
        </div>
        <div class="box-rg">
          <span>
            {{ form.shopName }}
          </span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span>店铺ID</span>
        </div>
        <div class="box-rg">
          <span>{{ form.shopId }}</span>
        </div>
      </div>
      <h3 class="g">法人信息</h3>
      <div class="box-item">
        <div class="box-lf">
          <span>法人姓名</span>
        </div>
        <div class="box-rg">
          <span>{{ form.realName }}</span>
        </div>
      </div>

      <div class="box-item">
        <div class="box-lf">
          <span>法人证件号</span>
        </div>
        <div class="box-rg">
          <span>{{ form.idCardNumber }}</span>
        </div>
      </div>
      <div class="box-item">
        <div class="box-lf">
          <span class="i">法人电话</span>
        </div>

        <div class="box-rg">
          <el-input
            placeholder=" 请填写法人个人实名的电话号码"
            v-model.number="form.phone"
            size="small"
            style="width: 240px"
          ></el-input>
        </div>
      </div>
      <h3 class="g">相关证件</h3>
      <div class="box_bg">
        <div class="box-item">
          <div class="box-rg">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              name="files"
              :before-upload="beforeupload"
              :on-success="onUploadSuccess3"
              :on-error="onUploadError"
              :multiple="false"
              :headers="headers"
              :show-file-list="false"
            >
              <el-image
                class="avatar"
                v-if="form.businessImageUrl"
                fit="cover"
                :src="form.businessImageUrl"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="box-lf">
            <span>营业执照</span>
          </div>
        </div>
        <div class="box-item">
          <div class="box-rg">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              name="files"
              :before-upload="beforeupload"
              :on-success="onUploadSuccess4"
              :on-error="onUploadError"
              :multiple="false"
              :headers="headers"
              :show-file-list="false"
            >
              <el-image
                class="avatar"
                v-if="form.idCardFrontImageUrl"
                fit="cover"
                :src="form.idCardFrontImageUrl"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="box-lf">
            <span>身份证正面</span>
          </div>
        </div>
        <div class="box-item">
          <div class="box-rg">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              name="files"
              :before-upload="beforeupload"
              :on-success="onUploadSuccess5"
              :on-error="onUploadError"
              :multiple="false"
              :headers="headers"
              :show-file-list="false"
            >
              <el-image
                class="avatar"
                v-if="form.idCardBackImageUrl"
                fit="cover"
                :src="form.idCardBackImageUrl"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="box-lf">
            <span>身份证反面</span>
          </div>
        </div>
        <div class="box-item">
          <div class="box-rg">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              name="files"
              :before-upload="beforeupload"
              :on-success="onUploadSuccess6"
              :on-error="onUploadError"
              :multiple="false"
              :headers="headers"
              :show-file-list="false"
            >
              <el-image
                class="avatar"
                v-if="form.idCardHandImageUrl"
                fit="cover"
                :src="form.idCardHandImageUrl"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="box-lf">
            <span>手持身份证</span>
          </div>
        </div>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item prop="companyUrl">
        <h3 class="g">门头照片、店内环境</h3>
        <div style="display: flex">
          <div
            v-for="(item, index) in form.companyUrl"
            :key="index"
            class="shoplistimg"
            draggable="true"
            @dragstart="dragStart($event, index, item)"
            @drop="drop($event, index, item)"
          >
            <i class="el-icon-delete-solid delicon" @click="delimg(index)"></i>
            <el-image
              fit="cover"
              style="width: 148px; height: 148px; margin-right: 4px"
              :src="item"
            ></el-image>
          </div>
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            list-type="picture-card"
            :before-upload="beforeupload"
            :on-success="onUploadSuccess1"
            name="files"
            :on-error="onUploadError"
            :multiple="false"
            :headers="headers"
            :show-file-list="false"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="tips" style="margin-top: 5px">注：体现招牌、环境</div>
      </el-form-item>
      <el-form-item prop="accountLicenseImage">
        <h3 class="g">开户许可证照片</h3>
        <div style="display: flex">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            name="files"
            :before-upload="beforeupload"
            :on-success="onUploadSuccess2"
            :on-error="onUploadError"
            :multiple="false"
            :headers="headers"
            :show-file-list="false"
          >
            <el-image
              class="avatar"
              v-if="form.accountLicenseImage"
              fit="cover"
              :src="form.accountLicenseImage"
            ></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>

        <div class="tips" style="margin-top: 5px">
          注：实际货款转出的对公账户，如没有开户许可证，可A4纸打印一张
        </div>
      </el-form-item>
      <el-form-item prop="storeQrCodeUrl">
        <h3 class="g">店铺二维码</h3>
        <div style="display: flex">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            name="files"
            :before-upload="beforeupload"
            :on-success="onUploadSuccess7"
            :on-error="onUploadError"
            :multiple="false"
            :headers="headers"
            :show-file-list="false"
          >
            <el-image
              class="avatar"
              v-if="form.storeQrCodeUrl"
              fit="cover"
              :src="form.storeQrCodeUrl"
            ></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>

        <div class="tips" style="margin-top: 5px">注：店铺分享海报二维码</div>
      </el-form-item>
      <h3 class="g">对公账户信息</h3>
      <el-form-item
        label="开户名称:"
        prop="accountName"
        label-width="90px"
        class="ipt"
      >
        <el-input v-model="form.accountName" placeholder="请输入开户名称">
        </el-input>
      </el-form-item>
      <el-form-item
        label="开户账号:"
        prop="accountNumber"
        label-width="90px"
        class="ipt"
      >
        <el-input v-model="form.accountNumber" placeholder="请输入开户账号">
        </el-input>
      </el-form-item>
      <el-form-item
        label="开户银行:"
        prop="bankName"
        label-width="90px"
        class="ipt"
      >
        <el-select
          v-model="form.bankName"
          placeholder="请选择开户银行"
          clearable
          filterable
        >
          <el-option
            v-for="item in banksClassify"
            :key="item.id"
            :label="item.accountBank"
            :value="item.accountBank"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="开户支行:"
        prop="branchName"
        label-width="90px"
        class="ipt"
      >
        <el-input v-model="form.branchName" placeholder="请输入省份城市支行">
        </el-input>
      </el-form-item>
      <el-form-item label="厂家slogan:" label-width="100px">
        <el-input
          v-model="form.slogan"
          placeholder="请填写厂家宣传语"
          maxlength="20"
          class="ipt"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item style="position: relative; z-index: 0">
        <el-button
          v-if="
            storeType == 1 &&
            (form.status == 2 || form.status == 3 || form.status == null)
          "
          class="o"
          type="primary"
          @click="onSubmit()"
          >提交申请</el-button
        >
        <el-button v-if="storeType == 1 && form.status == 1" type="info"
          >申请已通过</el-button
        >
        <el-button v-if="storeType == 1 && form.status == 0" type="success"
          >资料审核中</el-button
        >
      </el-form-item>
    </el-form>
    <div></div>
    <el-dialog
      title="资料审核中"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <span class="tip">成为厂家资料正在审核中，请耐心等待...</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="o" @click="centerDialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="成为厂家申请失败"
      :visible.sync="centerDialogVisible1"
      width="30%"
      center
    >
      <span class="tip">拒绝理由:{{ form.statusReason }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="o"
          @click="centerDialogVisible1 = false"
          >再次申请</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
const defaultParams = {
  accountLicenseImage: "", //开户许可证
  accountName: "", //开户名称
  accountNumber: "", //开户账户,
  bankName: "", //银行名称
  branchName: "", //开户支行
  businessImageUrl: "", //营业执照
  companyUrl: [], //门店图片
  idCardBackImageUrl: "", //身份证反面
  idCardFrontImageUrl: "", //身份证正面
  idCardHandImageUrl: "", //手持身份证
  storeQrCodeUrl: "", //店铺二维码
  idCardNumber: "", //身份证号
  phone: "", //电话
  realName: "", //姓名
  shopName: "", //厂家名称
  slogan: "", //宣传语
};
export default {
  data() {
    return {
      centerDialogVisible: false, //弹窗显示
      centerDialogVisible1: false, //弹窗显示
      uploadData: {
        id: null,
        userId: null,
      }, // 图片上传数据
      uploadUrl: process.env.VUE_APP_API + "/api/common/fileInfo/newUpload",
      computerImageUrl: "", // 电脑端上传图片地址
      // 校验规则
      rules: {
        accountName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        accountNumber: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        branchName: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        slogan: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      addressDialogVisible: false, // 地址弹窗显示控制
      form: Object.assign({}, defaultParams), //表单参数
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      //银行分类
      banksClassify: [],
      shopId: JSON.parse(localStorage.getItem("store")).user.shopId, //商家id
      storeType: JSON.parse(localStorage.getItem("store")).user.storeType, //厂家类型
    };
  },
  created() {
    this.getInfo();
    this.getBankList();
    this.uploadData.userId = this.$store.state.user.userId;
  },
  // 判断申请状态
  watch: {
    "form.status": {
      handler(newVal) {
        console.log(newVal);
        if (newVal == 0) {
          this.centerDialogVisible = true;
        } else if (newVal == 2) {
          this.centerDialogVisible1 = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 上传提交
    async onSubmit() {
      let api;
      if (!this.form.id) {
        api = "reqSaveShop";
      } else {
        api = "reqUpdateShop";
      }
      this.form.companyUrl = this.form.companyUrl.join(",");
      const res = await this.$api.user[api](this.form);
      if (res.success) {
        this.$message({
          type: "success",
          message: res.message || "保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: res.message || "保存失败",
        });
      }
      this.getInfo();
    },
    // 上传文件之前的钩子
    beforeupload(file) {
      const isJPG = file.type == "image/png" || file.type == "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 照片上传成功
    onUploadSuccess1(res, file, fileList) {
      if (this.form.companyUrl.length < 6) {
        this.form.companyUrl.push(res.data[0].fileUrl);
      } else {
        this.$message({
          message: "最多添加六张图片",
          type: "warning",
        });
      }
    },
    // 照片上传成功
    onUploadSuccess2(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.accountLicenseImage = res.data[0].fileUrl;
    },
    // 照片上传成功
    onUploadSuccess3(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.businessImageUrl = res.data[0].fileUrl;
    },
    // 照片上传成功
    onUploadSuccess4(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.idCardFrontImageUrl = res.data[0].fileUrl;
    },
    // 照片上传成功
    onUploadSuccess5(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.idCardBackImageUrl = res.data[0].fileUrl;
    },
    // 照片上传成功
    onUploadSuccess6(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.idCardHandImageUrl = res.data[0].fileUrl;
    },
    // 照片上传成功
    onUploadSuccess7(res, file, fileList) {
      if (res.success) {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
      this.form.storeQrCodeUrl = res.data[0].fileUrl;
    },
    //删除当前轮播图片
    delimg(ind) {
      this.form.companyUrl.splice(ind, 1);
    },
    //当上传失败时
    onUploadError(err, file, fileList) {
      console.log("上传图片失败：", err);
    },
    // 获取厂家信息
    getInfo() {
      this.$api.user.reqGetShop({ shopId: this.shopId }).then((res) => {
        const data = res.data;
        this.uploadData.id = data.id;
        this.form = data;
        this.form.companyUrl = data.companyUrl.split(",");
      });
    },
    // 获取银行列表
    async getBankList() {
      const res = await this.$api.user.reqBankList();
      this.banksClassify = res.data;
    },
    // 照片上传成功
    handleAvatarSuccess(res, file) {
      if (res.success) {
        this.$message({
          message: res.message || "获取厂家信息成功",
          type: "success",
        });
      }
      this.computerImageUrl = res.data[0].fileUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-uploader {
  border: 2px dashed #f3f3f3;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.upload-demo {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  align-items: center;
}
.content {
  padding-left: 30px;
  .box {
    font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
    font-weight: 400;
    color: #606266;
    font-size: 14px;
    margin-top: 24px;

    .box-item {
      margin: 16px 0;
      display: flex;
      align-items: center;
      .box-lf {
        width: 120px;
      }
      .box-rg {
        color: #999999;
        .eidt {
          display: inline-block;
          color: #ff5000;
          margin-left: 8px;
          cursor: pointer;
        }
        .upload-box {
          cursor: pointer;
          width: 120px;
          height: 120px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
        }
      }
    }
    .box_bg {
      display: flex;
      .box-item {
        display: flex;
        flex-flow: column;
        margin-right: 40px;
        .box-lf {
          padding-top: 10px;
          text-align: center;
        }
      }
    }
  }
  .g {
    color: #03b615;
  }
  .ipt {
    width: 450px;
  }
  .tips {
    color: red;
    font-size: 12px;
  }
  .o {
    background-color: #f98600;
    border: 1px solid #f98600;
  }
  .shoplistimg {
    position: relative;
  }
  .delicon {
    cursor: pointer;
    color: #606266;
    position: absolute;
    padding: 2px;
    z-index: 9;
    right: 4px;
    top: 0px;
    font-size: 22px;
  }
  .tip {
    font-size: 28px;
    color: #000;
  }
  .i::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
